@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
	margin: 0;
	font-family: 'Quicksand', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mobile_only {
	display: block;
	background-color: #5f649b;
	flex-direction: row;
	flex: 1;
	min-height: 100vh;
}

.dpa-text {
	line-height: 1.6;
	font-size: 20px;
	/* font-weight: 500; */
	color: rgba(31, 31, 67, 0.7);
}

.dpa-text p {
	text-align: left;
	color: rgba(31, 31, 67, 0.7);
	text-align: justify;
}

.dpa-text ol {
	text-align: left !important;
	color: rgba(31, 31, 67, 0.7);
	font-size: 22px;
}
.dpa-text li {
	text-align: left !important;
	color: rgba(31, 31, 67, 0.7);
}

.appendix p {
	margin: 0;
}

.confirm-button {
	height: 50px;
	padding: 0 50px;
	border-radius: 100px;
	background-color: #5e639b;
	border: 0px;
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	color: #fddf6d;
	cursor: pointer !important;
	line-height: 45px;
	pointer-events: auto;
}

.i-agree-text {
	color: rgba(31, 31, 67, 0.7);
	font-weight: 500;
}

.agree-button {
	padding: 0;
	border: unset;
	cursor: pointer !important;
	background-color: unset;
	width: 24px;
	height: 24px;
	margin: unset;
	/* margin-top: 2px; */
	pointer-events: auto;
	/* cursor: alias !important; */
}

.agree-button-circle {
	background-color: #9392ad;
	width: 9px !important;
	height: 9px !important;
	border-radius: 2px;
}

.disabled-button {
	background-color: #bebece !important;
	color: white !important;
	cursor: default;
}
